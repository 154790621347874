import {Provider} from '@preact/prerender-data-provider';
import {Router} from "preact-router";
import './styles/main.scss';
import Index from "./routes";
import Payment from "./routes/payment";
import Receipt from "./routes/receipt";
import Talk from "./routes/talk";


if (window.location.hostname === "localhost" && process.env.NODE_ENV === 'development') {
    require("preact/debug");
}

const App = (props) => {
    return (
        <Provider value={props}>
            <Router>
                <Index path="/"/>
                <Talk path="/talk"/>
                <Receipt path="/receipt"/>
                <Payment path="/payment"/>
            </Router>
        </Provider>
    )
}

export default App;
